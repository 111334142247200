import ReactDOM from "react-dom/client";
import { App } from "@src/App";

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { loadRecapcha } from "./ReCaptcha";
import { loadSentry } from "./Sentry";

loadRecapcha();
loadSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <SentryErrorBoundary
    fallback={<p>Fatal: Une erreur inconnue est survenue</p>}
  >
    <App />
  </SentryErrorBoundary>
);
