import { findStepNameByPath } from "@src/Steps/Steps";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { navigationSession } from "./NavigationSession";
import { getNavigationDirection } from "./useStepNavigation.utils";

type Location = {
  pathname: string;
  search: string;
};

export function useSessionLocation(
  setIsTransitionning: Dispatch<SetStateAction<boolean>>,
  history: StepName[],
  isLoading: boolean
) {
  const [location, setLocation] = useState<Location>({
    pathname: window.location.pathname,
    search: window.location.search,
  });

  const handlePopState = useCallback(() => {
    const pageSessionId =
      window.history.state !== null && typeof window.history.state === "object"
        ? (window.history.state as Record<string, unknown>).sessionId
        : null;

    const nextPathname = findStepNameByPath(window.location.pathname);
    if (
      pageSessionId === navigationSession.id &&
      nextPathname &&
      getNavigationDirection(history, nextPathname) === "backward" &&
      isLoading
    ) {
      const confirmUnsafeNavigation = window.confirm(
        "Voulez-vous vraiment quitter votre parcours MUTA ?\nToute votre saisie pourrait être perdue.\n"
      );
      if (confirmUnsafeNavigation) {
        navigationSession.invalidate();
      } else {
        setIsTransitionning(false);
        window.history.go(1);
        return;
      }
    }
    // ignore pages that were not created during this navigation session
    if (pageSessionId !== navigationSession.id) {
      window.history.go(-1);
    } else {
      setLocation({
        pathname: window.location.pathname,
        search: window.location.search,
      });
    }
  }, [isLoading, history]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [isLoading]);

  return location;
}
