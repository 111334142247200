export function loadRecapcha() {
  const loadScriptByURL = (id: string, url: string) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      document.body.appendChild(script);
    }
  };

  // load the script by passing the URL
  if (import.meta.env.VITE_RECAPTCHA_KEY)
    loadScriptByURL(
      import.meta.env.VITE_RECAPTCHA_KEY,
      `https://www.google.com/recaptcha/api.js?render=${
        import.meta.env.VITE_RECAPTCHA_KEY
      }`
    );
}

export const fetchRecaptchaToken = async (): Promise<string | undefined> => {
  /* eslint-disable @typescript-eslint/no-unsafe-assignment  */
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  if (!window.grecaptcha) return undefined;
  return new Promise((resolve) => {
    if (!window.grecaptcha) resolve(undefined);
    else {
      window.grecaptcha.ready(() => {
        if (!window.grecaptcha || !import.meta.env.VITE_RECAPTCHA_KEY)
          resolve(undefined);
        else {
          window.grecaptcha
            .execute(import.meta.env.VITE_RECAPTCHA_KEY, {
              action: "submit",
            })
            .then((token) => {
              resolve(token);
            })
            .catch(() => {
              resolve(undefined);
            });
        }
      });
    }
  });
  /* eslint-enable @typescript-eslint/no-unsafe-assignment  */
  /* eslint-enable @typescript-eslint/no-unsafe-call */
};
