import React from "react";

const AdherentCDTFStep = React.lazy(() => import("./AdherentCDTF.step"));
const CoordonnesBancairesStep = React.lazy(
  () => import("./CoordonnesBancaires.step")
);
const DevisEnvoyeStep = React.lazy(() => import("./DevisEnvoye.step"));
const NumSecuStep = React.lazy(() => import("./NumSecu.step"));
const NumSecuConjointStep = React.lazy(() => import("./NumSecuConjoint.step"));
const NumSecuEnfantsStep = React.lazy(() => import("./NumSecuEnfants.step"));
const RecapSouscriptionStep = React.lazy(
  () => import("./RecapSouscription.step")
);
const ResiliationStep = React.lazy(() => import("./Resiliation.step"));
const ResiliationConjointStep = React.lazy(
  () => import("./ResiliationConjoint.step")
);
const SouscriptionMajStep = React.lazy(() => import("./SouscriptionMaj.step"));
const TelPortableStep = React.lazy(() => import("./TelPortable.step"));

export const STEPS_SOUSCRIPTION = {
  ADHERENT_CDTF: {
    hasPrevious: true,
    progress: 40,
    path: "/adherent-cdtf",
    component: AdherentCDTFStep,
  },
  COORDONNES_BANCAIRES: {
    hasPrevious: true,
    progress: 45,
    path: "/coordonnes-bancaires",
    component: CoordonnesBancairesStep,
  },
  NUM_SECU: {
    hasPrevious: true,
    progress: 10,
    path: "/num-secu",
    component: NumSecuStep,
  },
  NUM_SECU_CONJOINT: {
    hasPrevious: true,
    progress: 15,
    path: "/num-secu-conjoint",
    component: NumSecuConjointStep,
  },
  NUM_SECU_ENFANTS: {
    hasPrevious: true,
    progress: 20,
    path: "/num-secu-enfants",
    component: NumSecuEnfantsStep,
  },
  RECAP_SOUSCRIPTION: {
    hasPrevious: true,
    progress: 5,
    path: "/recap-souscription",
    component: RecapSouscriptionStep,
  },
  DEVIS_ENVOYE: {
    hasPrevious: false,
    progress: null,
    path: "/devis-envoye",
    component: DevisEnvoyeStep,
  },
  RESILIATION: {
    hasPrevious: true,
    progress: 25,
    path: "/resiliation",
    component: ResiliationStep,
  },
  RESILIATION_CONJOINT: {
    hasPrevious: true,
    progress: 30,
    path: "/resiliation-conjoint",
    component: ResiliationConjointStep,
  },
  TEL_PORTABLE: {
    hasPrevious: true,
    progress: 5,
    path: "/tel-portable",
    component: TelPortableStep,
  },
  SOUSCRIPTION_MAJ: {
    hasPrevious: true,
    startLoading: true,
    progress: null,
    path: "/souscription-maj",
    component: SouscriptionMajStep,
  },
};
