import "./loaderAnimation.scss";

export function LoaderAnimation({
  size = "default",
}: {
  size?: "default" | "xs";
}): JSX.Element {
  return (
    <div className="loader-animation" data-size={size}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
