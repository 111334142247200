import React from "react";

const AvantDePoursuivreStep = React.lazy(
  () => import("./AvantDePoursuivre.step")
);
const CompleterVotreDossierStep = React.lazy(
  () => import("./CompleterVotreDossier.step")
);
const SignatureCreationStep = React.lazy(
  () => import("./SignatureCreation.step")
);

export const STEPS_DOSSIER = {
  AVANT_DE_POURSUIVRE: {
    hasPrevious: true,
    progress: 70,
    path: "/avant-de-poursuivre",
    component: AvantDePoursuivreStep,
  },
  COMPLETER_VOTRE_DOSSIER: {
    hasPrevious: false,
    progress: 55,
    path: "/completer-votre-dossier",
    component: CompleterVotreDossierStep,
  },
  SIGNATURE_CREATION: {
    hasPrevious: true,
    startLoading: true,
    progress: null,
    path: "/signature-creation",
    component: SignatureCreationStep,
  },
};
