import React from "react";

const CoordonneesStep = React.lazy(() => import("./Coordonnees.step"));
const DevisCreationStep = React.lazy(() => import("./DevisCreation.step"));
const InfoConjointStep = React.lazy(() => import("./InfoConjoint.step"));
const InfoEnfantsStep = React.lazy(() => import("./InfoEnfants.step"));
const InfoPersoStep = React.lazy(() => import("./InfoPerso.step"));

export const STEPS_INFORMATIONS = {
  COORDONNEES: {
    hasPrevious: true,
    progress: 50,
    path: "/coordonnes",
    component: CoordonneesStep,
  },
  DEVIS_CREATION: {
    hasPrevious: true,
    startLoading: true,
    progress: null,
    path: "/devis-creation",
    component: DevisCreationStep,
  },
  INFO_CONJOINT: {
    hasPrevious: true,
    progress: 20,
    path: "/info-conjoint",
    component: InfoConjointStep,
  },
  INFO_ENFANTS: {
    hasPrevious: true,
    progress: 35,
    path: "/info-enfants",
    component: InfoEnfantsStep,
  },
  INFO_PERSO: {
    hasPrevious: true,
    progress: 5,
    path: "/info-perso",
    component: InfoPersoStep,
  },
};
