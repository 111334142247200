import { base64ToJsonOject } from "./base64";

export function getUrlParamBase64<T = string>(name: string): T | null {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    const base64 = params.get(name);
    return base64ToJsonOject<T>(base64);
  }
  return null;
}

export function getUrlParam(name: string): string | null {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  }
  return null;
}
