import { LoaderAnimation } from "./LoaderAnimation";
import "./loaderBlock.scss";

type LoaderBlockProps = {
  isLoading?: boolean;
  isTransparent?: boolean;
};
export function LoaderBlock({ isLoading, isTransparent }: LoaderBlockProps) {
  return (
    <div
      className="loader-block"
      data-loading={isLoading}
      data-transparent={isTransparent}
    >
      <LoaderAnimation />
    </div>
  );
}
