import { getUrlParamBase64 } from "../utils/url";
import "./topBar.scss";

export function TopBar() {
  const advisor = getUrlParamBase64<{ nom: string }>("conseiller");
  return (
    <div className="top-bar">
      <img
        className="top-bar__logo"
        src="/muta-logo.webp"
        alt="muta-sante-logo"
      />
      {advisor !== null && <div className="top-bar__nom">{advisor.nom}</div>}
    </div>
  );
}
