export function utf8ToBase64(str: string): string {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function base64ToUtf8(base64: string): string {
  return decodeURIComponent(escape(window.atob(base64)));
}
export function base64ToJsonOject<T = unknown>(
  base64: string | null
): T | null {
  if (!base64) return null;
  try {
    const json = base64ToUtf8(base64);
    return JSON.parse(json) as T;
  } catch (e) {
    return null;
  }
}
