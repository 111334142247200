import { createPortal } from "react-dom";
import "./previousButton.scss";

type PreviousButtonProps = {
  changeStep: (nextStep: StepName) => void;
  history: StepName[];
  disabled?: boolean;
  hidden?: boolean;
};

export function PreviousButton({
  changeStep,
  history,
  disabled,
  hidden,
}: PreviousButtonProps) {
  if (history.length <= 1) return null;

  return createPortal(
    <button
      data-hidden={hidden}
      disabled={disabled}
      className="previous-button"
      onClick={() => changeStep(history[history.length - 2])}
    >
      Précédent
    </button>,
    document.getElementById("previous-button-wrapper") as Element
  );
}
