import { useEffect, useRef } from "react";

export function useIsFirstLoad() {
  const isFirstLoad = useRef(true);

  useEffect(() => {
    isFirstLoad.current = false;
  }, []);

  return isFirstLoad.current;
}
