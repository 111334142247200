export enum RegimeSecu {
  GENERAL = "GENERAL:REGIME_SECU_TYPE",
  ALSACE_MOSELLE = "ALSACE:REGIME_SECU_TYPE",
  LAMAL = "LAMAL:REGIME_SECU_TYPE",
  LAMAL_LOCAL_FRONTALIER = "LAMAL_FRONTALIER:REGIME_SECU_TYPE",
}

export enum SituationStandard {
  EN_ACTIVITE = "EN_ACTIVITE",
  EN_RETRAITE = "EN_RETRAITE",
  AU_CHOMAGE = "AU_CHOMAGE",
}

export enum ProjetEtat {
  MODALITE_SOUSCRIPTION_EN_ATTENTE = "MODALITE_SOUSCRIPTION_EN_ATTENTE:ETAT_PROJET",
  DOSSIER_EN_ATTENTE = "DOSSIER_EN_ATTENTE:ETAT_PROJET",
  DOSSIER_VALIDATION_EN_ATTENTE = "DOSSIER_VALIDATION_EN_ATTENTE:ETAT_PROJET",
  DOSSIER_VALIDE = "DOSSIER_VALIDE:ETAT_PROJET",
}

export enum StatutProfessionnel {
  SALARIE = "SALARIE:STATUT_PROFESSIONNEL_TYPE",
  TRAVAILLEUR_INDEPENDANT = "TRAVAILLEUR_INDEPENDANT:STATUT_PROFESSIONNEL_TYPE",
  RETRAITE = "RETRAITE:STATUT_PROFESSIONNEL_TYPE",
  SANS_PROFESSION = "SANS_PROFESSION:STATUT_PROFESSIONNEL_TYPE",
  FRONTALIER_SUISSE = "FRONTALIER_SUISSE:STATUT_PROFESSIONNEL_TYPE",
  AGENT_TERRITORIAL_TITULAIRE = "AGENT_TERRITORIAL_TITULAIRE:STATUT_PROFESSIONNEL_TYPE",
  AGENT_TERRITORIAL_NON_TITULAIRE = "AGENT_TERRITORIAL_NON_TITULAIRE:STATUT_PROFESSIONNEL_TYPE",
}

export enum SituationAgentTerritorial {
  EN_ACTIVITE_TITULAIRE = "EN_ACTIVITE_TITULAIRE",
  EN_ACTIVITE_NON_TITULAIRE = "EN_ACTIVITE_NON_TITULAIRE",
  RETRAITE = "RETRAITE",
}

export enum ComplementairePersonne {
  PAS_DE_COMPLEMENTAIRE = "PAS_DE_COMPLEMENTAIRE",
  ARRIVE_A_TERME = "ARRIVE_A_TERME",
  SOUHAIT_REMPLACEMENT = "SOUHAIT_REMPLACEMENT",
}

export enum Offre {
  STANDARD = "STANDARD",
  FRONTALIER = "FRONTALIER",
  TNS = "TNS",
  AGENT_TERRITORIAL = "AGENT_TERRITORIAL",
}

export enum TypeCouverture {
  HOSPITALISATION = "HOSPITALISATION",
  SOINS_COURANTS = "SOINS_COURANTS",
  AIDES_AUDITIVES = "AIDES_AUDITIVES",
  DENTAIRE = "DENTAIRE",
  OPTIQUE = "OPTIQUE",
  MEDECINE_COMPLEMENTAIRE = "MEDECINE_COMPLEMENTAIRE",
  CURE = "CURE",
}

export enum FORMULES_STANDARD {
  "Primo" = "Primo",
  "Bronze" = "Bronze",
  "Argent" = "Argent",
  "Or" = "Or",
  "Platine" = "Platine",
  "Diamant" = "Diamant",
}

export enum FORMULES_TNS {
  "Bronze" = "Bronze",
  "Argent" = "Argent",
  "Or" = "Or",
  "Platine" = "Platine",
  "TNS MAXI" = "TNS MAXI",
}

export enum FORMULES_FRONTALIER {
  "Primo" = "Primo",
  "Bronze" = "Bronze",
  "Argent" = "Argent",
  "Or" = "Or",
}

export enum FORMULES_AGENT_TERRITORIAL {
  "Base" = "Base",
  "Médiane" = "Médiane",
  "Maximale" = "Maximale",
}

export type FORMULES_ANY =
  | FORMULES_STANDARD
  | FORMULES_AGENT_TERRITORIAL
  | FORMULES_FRONTALIER
  | FORMULES_TNS;
