import React from "react";

const DateEmbaucheAgentTerritorialStep = React.lazy(
  () => import("./DateEmbaucheAgentTerritorial.step")
);

export const STEPS_DATE_SOUSCRIPTION_AGENT_TERRITORIAL = {
  DATE_EMBAUCHE_AGENT_TERRITORIAL: {
    hasPrevious: true,
    progress: 85,
    path: "/date-embauche-agent-territorial",
    component: DateEmbaucheAgentTerritorialStep,
  },
};
