import { Offre } from "@src/consts";
import { findStepNameByPath, STEPS } from "@src/Steps/Steps";
import { useIsFirstLoad } from "@src/utils/useIsFirstLoad";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { StateProjet } from "@src/App";
import { useSessionLocation } from "./useSessionLocation";
import {
  getChangeStepFn,
  getInitialActiveStep,
  getNavigationDirection,
  getNextHistory,
  getNextStepsData,
  useNavigateToInitialStepOnMount,
} from "./useStepNavigation.utils";

export type NavigationDirection = "forward" | "backward";

type UseTransitionOnLocationChangeProps = {
  setNavigationDirection: Dispatch<SetStateAction<NavigationDirection | null>>;
  history: StepName[];
  setHistory: Dispatch<SetStateAction<StepName[]>>;
  dataHistory: StepName[];
  setDataHistory: Dispatch<SetStateAction<StepName[]>>;
  stepsData: StepsData;
  setStepsData: Dispatch<SetStateAction<StepsData>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsTransitioning: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

function useTransitionOnLocationChange({
  setNavigationDirection,
  history,
  setHistory,
  dataHistory,
  setDataHistory,
  stepsData,
  setStepsData,
  setIsLoading,
  setIsTransitioning,
  isLoading,
}: UseTransitionOnLocationChangeProps) {
  const { pathname } = useSessionLocation(
    setIsTransitioning,
    history,
    isLoading
  );
  const isFirstLoad = useIsFirstLoad();
  const transitionTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!isFirstLoad) {
      const nextStepName = findStepNameByPath(pathname);
      if (!nextStepName) return;
      if ((STEPS[nextStepName] as { startLoading?: boolean }).startLoading) {
        setIsLoading(true);
      }
      clearTimeout(transitionTimeoutRef.current);
      const navigationDirection = getNavigationDirection(history, nextStepName);
      setNavigationDirection(navigationDirection);
      transitionTimeoutRef.current = setTimeout(() => {
        const nextHistory = getNextHistory(
          history,
          nextStepName,
          navigationDirection
        );
        if (navigationDirection === "forward") {
          const { nextDataHistory, nextStepsData } = getNextStepsData(
            history,
            dataHistory,
            stepsData,
            history[history.length - 1]
          );
          setDataHistory(nextDataHistory);
          setStepsData(nextStepsData);
        }
        setHistory(nextHistory);

        setNavigationDirection(null);
        if (!(STEPS[nextStepName] as { startLoading?: boolean }).startLoading) {
          setIsLoading(false);
        }
        setIsTransitioning(false);

        window.document.body.scrollTo({ top: 0 });
      }, 200);
    }
  }, [pathname, isFirstLoad]);
}

function useNavigationTransition() {
  const [navigationDirection, setNavigationDirection] =
    useState<NavigationDirection | null>(null);
  const lastNavigationRef = useRef<NavigationDirection | null>(null);
  const lastTransitionRef = useRef<string | null>(null);

  let transition;

  if (navigationDirection === "forward") transition = "slide-out-left";
  else if (navigationDirection === "backward") transition = "slide-out-right";
  else if (lastNavigationRef.current === "forward")
    transition = "slide-in-right";
  else if (lastNavigationRef.current === "backward")
    transition = "slide-in-left";
  else transition = lastTransitionRef.current;

  lastNavigationRef.current = navigationDirection;
  lastTransitionRef.current = transition;

  return {
    setNavigationDirection,
    transition,
  };
}

export function useStepNavigation(
  projet: StateProjet | null,
  selectedOffre: Offre | null,
  projetHasError?: boolean,
  unhandledError?: boolean
) {
  const initialStepName = useMemo(() => {
    return getInitialActiveStep(
      projet,
      selectedOffre,
      projetHasError,
      unhandledError
    );
  }, []);
  const { transition, setNavigationDirection } = useNavigationTransition();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<StepName[]>([initialStepName]);
  const [stepsData, setStepsData] = useState<StepsData>({});
  const [dataHistory, setDataHistory] = useState<StepName[]>([]);
  const activeStepName = history[history.length - 1];
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const changeStep = getChangeStepFn(
    history,
    isTransitioning,
    setIsTransitioning
  );
  const goBackAfterError = () => {
    if (history.length >= 2) {
      setIsLoading(false);
      setTimeout(() => changeStep(history[history.length - 2]), 1);
    }
  };
  useNavigateToInitialStepOnMount(initialStepName);

  useTransitionOnLocationChange({
    setNavigationDirection,
    history,
    setHistory,
    dataHistory,
    setDataHistory,
    stepsData,
    setStepsData,
    setIsLoading,
    setIsTransitioning,
    isLoading,
  });

  return {
    activeStep: STEPS[activeStepName],
    changeStep,
    goBackAfterError,
    history,
    transition,
    stepsData,
    isLoading,
    setIsLoading,
    isTransitioning,
    updateActiveStepData: <T extends keyof StepsData>(
      newStepData: StepsData[T]
    ) => {
      const { nextDataHistory, nextStepsData } = getNextStepsData(
        history,
        dataHistory,
        stepsData,
        activeStepName
      );

      nextStepsData[activeStepName as T] = newStepData;
      setDataHistory(nextDataHistory);
      setStepsData(nextStepsData);
    },
  };
}
