import { navigationSession } from "./NavigationSession";

export const browserNavigateReplace = (nextPath: string) => {
  window.history.replaceState(
    {
      sessionId: navigationSession.id,
    },
    "",
    nextPath + window.location.search
  );
  window.dispatchEvent(new Event("popstate"));
};

export const browserNavigateForwardReset = (nextPath: string) => {
  navigationSession.invalidate();
  window.history.pushState(
    {
      sessionId: navigationSession.id,
    },
    "",
    nextPath + window.location.search
  );
  window.dispatchEvent(new Event("popstate"));
};

export const browserNavigateForward = (nextPath: string) => {
  window.history.pushState(
    {
      sessionId: navigationSession.id,
    },
    "",
    nextPath + window.location.search
  );
  window.dispatchEvent(new Event("popstate"));
};

export const browserNavigateBackward = (
  nextEntry: StepName,
  history: StepName[]
) => {
  const previousStepIndex = history.findIndex((entry) => entry === nextEntry);
  if (previousStepIndex === -1) return;
  window.history.go(previousStepIndex - (history.length - 1));
};
