import { Button } from "@lya-protect/lya-protect-form-library/dist/UI";
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useImperativeHandle,
  useState,
} from "react";
import "./sidePane.scss";

/* 
    eslint-disable react/function-component-definition
*/

type SidePaneProps = {
  hideToggle?: boolean;
  reduceToggle?: boolean;
  content: ReactElement;
  toggleContent: ReactElement;
  reducedToggleContent: ReactElement;
};

export type SidePaneHandle = {
  toggle: (opened: boolean) => void;
};
const SidePaneRenderFunction: ForwardRefRenderFunction<
  SidePaneHandle,
  SidePaneProps
> = (
  {
    content,
    reducedToggleContent,
    reduceToggle = false,
    hideToggle,
    toggleContent,
  },
  forwardedRef
) => {
  const [isOpened, setIsOpened] = useState(false);

  useImperativeHandle(forwardedRef, () => ({
    toggle(opened: boolean) {
      setIsOpened(opened);
    },
  }));

  return (
    <>
      <div
        className="side-pane__backdrop"
        data-visible={isOpened}
        tabIndex={-1}
        aria-hidden
        onClick={() => {
          setIsOpened(false);
        }}
      />
      <div className="side-pane" data-opened={isOpened}>
        <button
          className="side-pane__toggle"
          data-hidden={hideToggle}
          disabled={hideToggle}
          data-reduced={reduceToggle}
          onClick={() => setIsOpened(!isOpened)}
        >
          <div className="side-pane__toggle-content">{toggleContent}</div>
          <div className="side-pane__toggle-reduced-content">
            {reducedToggleContent}
          </div>
          <div className="side-pane__toggle-close">
            <svg
              className="side-pane__close-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </button>
        <fieldset
          tabIndex={-1}
          className="side-pane__content"
          disabled={!isOpened}
        >
          {content}
        </fieldset>
        <Button
          className="side-pane__close-button"
          variant="text"
          onClick={() => setIsOpened(false)}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="side-pane__close-button-icon"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </Button>
      </div>
    </>
  );
};

export const SidePane = forwardRef(SidePaneRenderFunction);
