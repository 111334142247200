import { STEPS_DATE_SOUSCRIPTION_AGENT_TERRITORIAL } from "./AgentTerritorial/stepsDateSouscriptionAgentTerritorial";
import { STEPS_DATE_SOUSCRIPTION_COMMON } from "./Common/stepsDateSouscriptionCommon";
import { STEPS_DATE_SOUSCRIPTION_FRONTALIER } from "./Frontalier/stepsDateSouscriptionFrontalier";
import { STEPS_DATE_SOUSCRIPTION_STANDARD } from "./Standard/stepsDateSouscriptionStandard";
import { STEPS_DATE_SOUSCRIPTION_TNS } from "./TNS/stepsDateSouscriptionTNS";

export const STEPS_DATE_SOUSCRIPTION = {
  ...STEPS_DATE_SOUSCRIPTION_STANDARD,
  ...STEPS_DATE_SOUSCRIPTION_TNS,
  ...STEPS_DATE_SOUSCRIPTION_FRONTALIER,
  ...STEPS_DATE_SOUSCRIPTION_AGENT_TERRITORIAL,
  ...STEPS_DATE_SOUSCRIPTION_COMMON,
};
