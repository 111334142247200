import React from "react";

const MentionsLegalesStep = React.lazy(() => import("./MentionsLegales.step"));

export const STEP_MENTION_LEGALES = {
  MENTIONS_LEGALES: {
    hasPrevious: true,
    progress: null,
    path: "/mentions-legales",
    component: MentionsLegalesStep,
  },
};
