import axios from "axios";
import * as Sentry from "@sentry/react";

class GatewayAPI {
  public axios;

  constructor() {
    this.axios = axios.create({
      baseURL: `//${import.meta.env.VITE_URL_GATEWAY}`,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        Sentry.captureException(error);
        throw error;
      }
    );
  }
}

export const gatewayAPI = new GatewayAPI();
