import React from "react";

const NosOffresStep = React.lazy(() => import("./NosOffres/NosOffres.step"));
const RecapChoixStep = React.lazy(() => import("./RecapChoix.step"));

export const STEPS_TARIFS = {
  NOS_OFFRES: {
    hasPrevious: true,
    progress: null,
    path: "/nos-offres",
    component: NosOffresStep,
    largeContent: true,
    headerClassName:
      "nos-offres-step__centered-content nos-offres-step__header",
  },
  RECAP_CHOIX: {
    hasPrevious: true,
    progress: null,
    path: "/recap-choix",
    component: RecapChoixStep,
  },
};
