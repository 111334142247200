import React from "react";

const DateEmbaucheFrontalierStep = React.lazy(
  () => import("./DateEmbaucheFrontalier.step")
);

export const STEPS_DATE_SOUSCRIPTION_FRONTALIER = {
  DATE_EMBAUCHE_FRONTALIER: {
    hasPrevious: true,
    progress: 85,
    path: "/date-embauche-frontalier",
    component: DateEmbaucheFrontalierStep,
  },
};
