// eslint-disable-next-line import/no-extraneous-dependencies
import { Offre } from "lya-protect-muta-form-back/src/consts";
// eslint-disable-next-line import/no-relative-packages
export * from "../../back/src/consts";

export const URL_SUFFIX_BY_OFFRE = {
  [Offre.AGENT_TERRITORIAL]: "agent-territorial",
  [Offre.FRONTALIER]: "frontalier",
  [Offre.STANDARD]: "standard",
  [Offre.TNS]: "tns",
};

export const TRANCHE_AGE = [31, 61, 66];
export const TRANCHE_AGE_TNS = [21, 26, 31, 36, 41, 46, 51, 56, 61, 66, 71];
