import React from "react";

import "./quelsBesoins.scss";
import "./vousConjointEnfants.scss";

const QuelsBesoinsAutresStep = React.lazy(
  () => import("./QuelsBesoinsAutres.step")
);
const QuelsBesoinsDentaireStep = React.lazy(
  () => import("./QuelsBesoinsDentaire.step")
);
const QuelsBesoinsHospitStep = React.lazy(
  () => import("./QuelsBesoinsHospit.step")
);
const QuelsBesoinsOptiqueStep = React.lazy(
  () => import("./QuelsBesoinsOptique.step")
);
const QuiAssurerStep = React.lazy(() => import("./QuiAssurer.step"));
const VosEnfantsStep = React.lazy(() => import("./VosEnfants.step"));
const VotreConjointStep = React.lazy(() => import("./VotreConjoint.step"));
const VousStep = React.lazy(() => import("./Vous.step"));

export const STEPS_BESOINS = {
  QUELS_BESOINS_HOSPIT: {
    hasPrevious: true,
    progress: 15,
    path: "/quels-besoins-hospit",
    component: QuelsBesoinsHospitStep,
  },
  QUELS_BESOINS_DENTAIRE: {
    hasPrevious: true,
    progress: 35,
    path: "/quels-besoins-dentaire",
    component: QuelsBesoinsDentaireStep,
  },
  QUELS_BESOINS_OPTIQUE: {
    hasPrevious: true,
    progress: 25,
    path: "/quels-besoins-optique",
    component: QuelsBesoinsOptiqueStep,
  },
  QUELS_BESOINS_AUTRES: {
    hasPrevious: true,
    progress: 45,
    path: "/quels-besoins-autres",
    component: QuelsBesoinsAutresStep,
  },
  QUI_ASSURER: {
    hasPrevious: true,
    progress: 5,
    path: "/qui-assurer",
    component: QuiAssurerStep,
  },
  VOS_ENFANTS: {
    hasPrevious: true,
    progress: 65,
    path: "/vos-enfants",
    component: VosEnfantsStep,
  },
  VOTRE_CONJOINT: {
    hasPrevious: true,
    progress: 60,
    path: "/votre-conjoint",
    component: VotreConjointStep,
  },
  VOUS: {
    hasPrevious: true,
    progress: 55,
    path: "/vous",
    component: VousStep,
  },
};
