import React from "react";

const PlusQuASignerStep = React.lazy(() => import("./PlusQuASigner.step"));

export const STEPS_SIGNATURE = {
  PLUS_QU_A_SIGNER: {
    hasPrevious: true,
    progress: 90,
    path: "/plus-qu-a-signer",
    component: PlusQuASignerStep,
  },
};
