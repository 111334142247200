import { BrowserTracing } from "@sentry/tracing";
import { init } from "@sentry/react";

export function loadSentry() {
  if (!["local", "production"].includes(import.meta.env.VITE_ENVIRONNEMENT)) {
    init({
      dsn: "https://2264b494259647dc892f84efb6138ab5@o4503970878455808.ingest.sentry.io/4504214915973120",
      integrations: [new BrowserTracing()],
      environment: import.meta.env.VITE_ENVIRONNEMENT,
      tracesSampleRate: parseFloat(
        import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || "1.0"
      ),
      release: import.meta.env.VITE_RELEASE,
    });
  }
}
