import React from "react";

const DateFinComplementaireStandardStep = React.lazy(
  () => import("./DateFinComplementaireStandard.step")
);
const FinComplementaireDepuisStandardStep = React.lazy(
  () => import("./FinComplementaireDepuisStandard.step")
);
const QuandAdhererStandardStep = React.lazy(
  () => import("./QuandAdhererStandard.step")
);
export const STEPS_DATE_SOUSCRIPTION_STANDARD = {
  DATE_FIN_COMPLEMENTAIRE_STANDARD: {
    hasPrevious: true,
    progress: 90,
    path: "/data-fin-complementaire-standard",
    component: DateFinComplementaireStandardStep,
  },
  FIN_COMPLEMENTAIRE_DEPUIS_STANDARD: {
    hasPrevious: true,
    progress: 80,
    path: "/fin-complementaire-depuis-standard",
    component: FinComplementaireDepuisStandardStep,
  },
  QUAND_ADHERER_STANDARD: {
    hasPrevious: true,
    progress: 90,
    path: "/quand-adherer-standard",
    component: QuandAdhererStandardStep,
  },
};
