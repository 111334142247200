import React from "react";

const ContactStep = React.lazy(() => import("./Contact.step"));
const ContactSuccessStep = React.lazy(() => import("./ContactSuccess.step"));

export const STEPS_CONTACT = {
  CONTACT: {
    hasPrevious: true,
    progress: 70,
    path: "/contact",
    component: ContactStep,
  },
  CONTACT_SUCCESS: {
    hasPrevious: false,
    progress: null,
    path: "/contact-success",
    component: ContactSuccessStep,
  },
};
