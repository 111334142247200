import React from "react";

const ErreurInconnueStep = React.lazy(() => import("./ErreurInconnue.step"));
const ErreurRecuperationProjetStep = React.lazy(
  () => import("./ErreurRecuperationProjet.step")
);
export const STEPS_ERREUR = {
  ERREUR_RECUPERATION_PROJET: {
    hasPrevious: false,
    progress: null,
    path: "/erreur-recuperation-projet",
    component: ErreurRecuperationProjetStep,
  },
  ERREUR_INCONNUE: {
    hasPrevious: false,
    progress: null,
    path: "/erreur-inconnue",
    component: ErreurInconnueStep,
  },
};
