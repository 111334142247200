import React from "react";

const QuandAdhererTNSStep = React.lazy(() => import("./QuandAdhererTNS.step"));

export const STEPS_DATE_SOUSCRIPTION_TNS = {
  QUAND_ADHERER_TNS: {
    hasPrevious: true,
    progress: 85,
    path: "/quand-adherer-tns",
    component: QuandAdhererTNSStep,
  },
};
