class NavigationSession {
  id: string;

  constructor() {
    this.id = NavigationSession.generateID();
  }

  invalidate() {
    this.id = NavigationSession.generateID();
  }

  private static generateID = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };
}

export const navigationSession = new NavigationSession();
