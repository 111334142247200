import React from "react";

const ComplementaireStep = React.lazy(() => import("./Complementaire.step"));
const DateFinComplementaireStep = React.lazy(
  () => import("./DateFinComplementaire.step")
);
const QuandChangerComplementaireStep = React.lazy(
  () => import("./QuandChangerComplementaire.step")
);
export const STEPS_DATE_SOUSCRIPTION_COMMON = {
  COMPLEMENTAIRE: {
    hasPrevious: true,
    progress: 70,
    path: "/complementaire",
    component: ComplementaireStep,
  },
  DATE_FIN_COMPLEMENTAIRE: {
    hasPrevious: true,
    progress: 85,
    path: "/date-fin-complementaire",
    component: DateFinComplementaireStep,
  },
  QUAND_CHANGER_COMPLEMENTAIRE: {
    hasPrevious: true,
    progress: 85,
    path: "/quand-changer-complementaire",
    component: QuandChangerComplementaireStep,
  },
};
