import React from "react";

import { Offre } from "../consts";

import { QuelsBesoinsAutresData } from "./Besoin/QuelsBesoinsAutres.step";
import { QuelsBesoinsDentaireData } from "./Besoin/QuelsBesoinsDentaire.step";
import { QuelsBesoinsHospitData } from "./Besoin/QuelsBesoinsHospit.step";
import { QuelsBesoinsOptiqueData } from "./Besoin/QuelsBesoinsOptique.step";
import { QuiAssurerStepData } from "./Besoin/QuiAssurer.step";
import { VosEnfantsFormValues } from "./Besoin/VosEnfants.step";
import { VotreConjointStepFormValues } from "./Besoin/VotreConjoint.step";
import { VousStepFormValues } from "./Besoin/Vous.step";
import { ContactStepFormValues } from "./Contact/Contact.step";
import { DateEmbaucheAgentTerritorialFormValues } from "./DateSouscription/AgentTerritorial/DateEmbaucheAgentTerritorial.step";
import { ComplementaireData } from "./DateSouscription/Common/Complementaire.step";
import { DateFinComplementaireFormValues } from "./DateSouscription/Common/DateFinComplementaire.step";
import { QuandChangerComplementaireFormValues } from "./DateSouscription/Common/QuandChangerComplementaire.step";
import { DateEmbaucheFrontalierFormValues } from "./DateSouscription/Frontalier/DateEmbaucheFrontalier.step";
import { DateFinComplementaireStandardFormValues } from "./DateSouscription/Standard/DateFinComplementaireStandard.step";
import { FinComplementaireDepuisStandardData } from "./DateSouscription/Standard/FinComplementaireDepuisStandard.step";
import { QuandAdhererStandardFormValues } from "./DateSouscription/Standard/QuandAdhererStandard.step";
import { QuandAdhererTNSFormValues } from "./DateSouscription/TNS/QuandAdhererTNS.step";
import { AvantDePoursuivreFormValues } from "./Dossier/AvantDePoursuivre.step";
import { CompleterVotreDossierFormValues } from "./Dossier/CompleterVotreDossier.step";
import { Funnel1StepData } from "./Funnel/Funnel1.step";
import { Funnel2StepData } from "./Funnel/Funnel2.step";
import { CoordonneesFormValues } from "./Informations/Coordonnees.step";
import { InfoConjointFormValues } from "./Informations/InfoConjoint.step";
import { InfoEnfantsFormValues } from "./Informations/InfoEnfants.step";
import { InfoPersoFormValues } from "./Informations/InfoPerso.step";
import { AdherentCDTFFormValues } from "./Souscription/AdherentCDTF.step";
import { CoordonnesBancairesFormValues } from "./Souscription/CoordonnesBancaires.step";
import { NumSecuFormValues } from "./Souscription/NumSecu.step";
import { NumSecuConjointFormValues } from "./Souscription/NumSecuConjoint.step";
import { NumSecuEnfantFormValues } from "./Souscription/NumSecuEnfants.step";
import { ResiliationFormValues } from "./Souscription/Resiliation.step";
import { ResiliationConjointFormValues } from "./Souscription/ResiliationConjoint.step";
import { STEPS_SOUSCRIPTION } from "./Souscription/stepsSouscription";
import { TelPortableFormValues } from "./Souscription/TelPortable.step";
import { NosOffreData } from "./Tarifs/NosOffres/NosOffres.step";
import { RecapChoixData } from "./Tarifs/RecapChoix.step";

import { STEPS_DOSSIER } from "./Dossier/StepsDossier";
import { STEPS_ERREUR } from "./Erreur/StepErreur";
import { STEPS_FUNNEL } from "./Funnel/stepsFunnel";
import { STEPS_DATE_SOUSCRIPTION } from "./DateSouscription/stepsDateSouscription";
import { STEPS_INFORMATIONS } from "./Informations/stepsInformations";
import { STEP_MENTION_LEGALES } from "./MentionsLegales/stepMentionsLegale";
import { STEPS_TARIFS } from "./Tarifs/stepsTarifs";
import { STEPS_SIGNATURE } from "./Signature/stepsSignature";
import { STEPS_CONTACT } from "./Contact/stepsContact";
import { STEPS_BESOINS } from "./Besoin/stepsBesoin";

export const STEPS = {
  ...STEPS_FUNNEL,
  ...STEPS_BESOINS,
  ...STEPS_CONTACT,
  ...STEPS_INFORMATIONS,
  ...STEPS_TARIFS,
  ...STEPS_SOUSCRIPTION,
  ...STEPS_DOSSIER,
  ...STEPS_SIGNATURE,
  ...STEP_MENTION_LEGALES,
  ...STEPS_DATE_SOUSCRIPTION,
  ...STEPS_ERREUR,
};

export const STEP_NAMES = Object.keys(STEPS).reduce((stepNames, key) => {
  (stepNames as Record<string, string>)[key] = key; // eslint-disable-line no-param-reassign
  return stepNames;
}, {}) as {
  [k in StepName]: k;
};

declare global {
  type StepsData = {
    [k in StepName]?: unknown;
  } & {
    [STEP_NAMES.FUNNEL1]?: Funnel1StepData;
    [STEP_NAMES.FUNNEL2]?: Funnel2StepData;
    [STEP_NAMES.CONTACT]?: ContactStepFormValues;
    [STEP_NAMES.QUI_ASSURER]?: QuiAssurerStepData;
    [STEP_NAMES.QUELS_BESOINS_AUTRES]?: QuelsBesoinsAutresData;
    [STEP_NAMES.QUELS_BESOINS_HOSPIT]?: QuelsBesoinsHospitData;
    [STEP_NAMES.QUELS_BESOINS_DENTAIRE]?: QuelsBesoinsDentaireData;
    [STEP_NAMES.QUELS_BESOINS_OPTIQUE]?: QuelsBesoinsOptiqueData;
    [STEP_NAMES.VOUS]?: VousStepFormValues;
    [STEP_NAMES.VOTRE_CONJOINT]?: VotreConjointStepFormValues;
    [STEP_NAMES.VOS_ENFANTS]?: VosEnfantsFormValues;
    [STEP_NAMES.NOS_OFFRES]?: NosOffreData;
    [STEP_NAMES.RECAP_CHOIX]?: RecapChoixData;
    [STEP_NAMES.INFO_PERSO]?: InfoPersoFormValues;
    [STEP_NAMES.INFO_CONJOINT]?: InfoConjointFormValues;
    [STEP_NAMES.INFO_ENFANTS]?: InfoEnfantsFormValues;
    [STEP_NAMES.COORDONNEES]?: CoordonneesFormValues;
    [STEP_NAMES.TEL_PORTABLE]?: TelPortableFormValues;
    [STEP_NAMES.NUM_SECU]?: NumSecuFormValues;
    [STEP_NAMES.NUM_SECU_CONJOINT]?: NumSecuConjointFormValues;
    [STEP_NAMES.NUM_SECU_ENFANTS]?: NumSecuEnfantFormValues;
    [STEP_NAMES.COORDONNES_BANCAIRES]?: CoordonnesBancairesFormValues;
    [STEP_NAMES.ADHERENT_CDTF]?: AdherentCDTFFormValues;
    [STEP_NAMES.COMPLETER_VOTRE_DOSSIER]?: CompleterVotreDossierFormValues;
    [STEP_NAMES.AVANT_DE_POURSUIVRE]?: AvantDePoursuivreFormValues;
    [STEP_NAMES.COMPLEMENTAIRE]?: ComplementaireData;
    [STEP_NAMES.DATE_FIN_COMPLEMENTAIRE]?: DateFinComplementaireFormValues;
    [STEP_NAMES.QUAND_CHANGER_COMPLEMENTAIRE]?: QuandChangerComplementaireFormValues;
    [STEP_NAMES.FIN_COMPLEMENTAIRE_DEPUIS_STANDARD]?: FinComplementaireDepuisStandardData;
    [STEP_NAMES.QUAND_ADHERER_STANDARD]?: QuandAdhererStandardFormValues;
    [STEP_NAMES.DATE_FIN_COMPLEMENTAIRE_STANDARD]?: DateFinComplementaireStandardFormValues;
    [STEP_NAMES.DATE_EMBAUCHE_FRONTALIER]?: DateEmbaucheFrontalierFormValues;
    [STEP_NAMES.QUAND_ADHERER_TNS]?: QuandAdhererTNSFormValues;
    [STEP_NAMES.DATE_EMBAUCHE_AGENT_TERRITORIAL]?: DateEmbaucheAgentTerritorialFormValues;
    [STEP_NAMES.RESILIATION]?: ResiliationFormValues;
    [STEP_NAMES.RESILIATION_CONJOINT]?: ResiliationConjointFormValues;
  };

  type StepComponentProps = {
    selectedOffre: Offre | null;
    setSelectedOffre: (offre: Offre | null) => void;
    stepsData: StepsData;
    duplicatedData: GetProjetResponse | null;
    setIsLoading: (isLoading: boolean) => void;
    setStepData: (data: StepsData[keyof StepsData]) => void;
    changeStep: (stepName: StepName) => void;
    goBackAfterError: () => void;
    projet: GetProjetResponse;
    setProjetData: <T extends keyof GetProjetResponse>(
      state: T,
      data: GetProjetResponse[T]
    ) => void;
  };

  type StepComponent = (props: StepComponentProps) => JSX.Element;

  type Step = {
    hasPrevious: boolean;
    component: StepComponent | React.LazyExoticComponent<StepComponent>;
    progress: number | null;
    path: string;
    headerClassName?: string;
    startLoading?: boolean;
    largeContent?: boolean;
  };

  type StepName = keyof typeof STEPS;
}

// trigger typescript validation
const _CHECK_STEPS: Record<string, Step> = STEPS; // eslint-disable-line

export function findStepNameByPath(pathname: string) {
  const step = Object.entries(STEPS).find(
    ([_, stepConfig]) => stepConfig.path === pathname // eslint-disable-line
  );
  return step ? (step[0] as StepName) : undefined;
}
