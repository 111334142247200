import React from "react";

const Funnel1Step = React.lazy(() => import("./Funnel1.step"));
const Funnel2Step = React.lazy(() => import("./Funnel2.step"));

export const STEPS_FUNNEL = {
  FUNNEL1: {
    hasPrevious: true,
    progress: 0,
    path: "/funnel",
    component: Funnel1Step,
  },
  FUNNEL2: {
    hasPrevious: true,
    progress: 5,
    path: "/funnel-more",
    component: Funnel2Step,
  },
};
